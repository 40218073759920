<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-edit-expense" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Edit Expense :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>
                <!-- {{ supplier_options }} -->
                <!-- {{ Category_options }} -->
                <validation-observer ref="addUserexpense">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">
                        <!-- <b-form-group id="date" label="Date:" label-for="date"> -->
                        <validation-provider #default="{ errors }" name="date" rules="required">
                            <b-form-datepicker id="date" v-model="form.date" placeholder="Select date" :value="form.date"
                                :max="new Date()" locale="en"></b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- </b-form-group> -->
                        <!-- {{ selected_Category }} {{ Category_options }} -->
                        <b-form-group id="Category" label="Category:" label-for="Category">
                            <validation-provider #default="{ errors }" name="Category" rules="required">
                                <b-form-select id="Category" v-model="selected_Category" :options="Category_options"
                                    class="demo-inline-spacing" placeholder="Categoies">
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="Amount" label="Amount:" label-for="Amount">
                            <validation-provider #default="{ errors }" name="Amount" rules="required">
                                <b-form-input id="Amount" v-model="form.Amount" placeholder="Amount" type="number">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="Tax" label="Tax:" label-for="Tax">
                            <validation-provider #default="{ errors }" name="Tax" rules="required">
                                <b-form-input id="Tax" v-model="form.Tax" placeholder="Tax" @input="formatTaxInput">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- {{ selected_supplier }} {{ supplier_options }} -->
                        <b-form-group id="supplier" label="Supplier:" label-for="input-supplier">
                            <b-form-select v-model="selected_supplier" :options="supplier_options"
                                class="demo-inline-spacing"></b-form-select>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Update
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import User from '../../../src/Helpers/User'
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, name, date,
    Amount,
    Tax,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Datepicker from 'vue-datepicker';
import { BFormDatepicker } from 'bootstrap-vue'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormDatepicker, BFormCheckboxGroup, BFormSelect,
    },
    directives: {
        Ripple,
    },
    props: {
        editexpense: {
            type: Object,
            required: true,
        }
    },
    watch: {
        editexpense() {
            // console.log('this.edit props', this.editsupplier.id)
            this.form.id = this.editexpense.id;
            // this.selected_supplier = this.editexpense.selected_supplier;
            // this.supplier_options = this.editexpense.supplier_options;
            this.form.date = this.editexpense.date;
            this.form.Amount = this.editexpense.amount;
            this.form.Tax = this.editexpense.tax;
            // this.Category_options = this.editexpense.categories.map((p) => c.id)
            this.selected_Category = this.editexpense.categories.map((p) => p.id)
        }
    },
    data() {
        return {
            required, name, date,
            Amount,
            Tax,
            form: {
                id: '',
                date: '',
                Amount: '',
                Tax: '',
            },
            selected_supplier: [],
            supplier_options: [
            ],
            selected_Category: [],
            Category_options: [
            ],
        }
    },

    mounted() {
        // // fetch data from API
        // axios.get('/api/roles/1')
        //     .then(response => {
        //         // assign response data to form object
        //         this.form = response.data;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    },
    methods: {
        created() {
            this.getAllsuppliers();
            this.getAllcategory();
        },
        checkPermission(permission) {

            return User.checkPermission(permission)
        },



        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        clearForm() {
            this.form.date = '',
                this.form.Category = '',
                this.form.Amount = '',
                this.form.Tax = ''
        },
        formatTaxInput() {
            if (this.form.Tax) {
                // Remove any non-numeric characters from the input value
                const numericValue = this.form.Tax.replace(/\D/g, '');
                // Append the "%" symbol at the end of the numeric value
                this.form.Tax = numericValue + "%";
            }
        },
        getAllsuppliers() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/super-supplier")
                .then((res) => {
                    this.loader = false;
                    this.supplier_options = res["data"].map((option) => ({
                        text: option.name, // Display name for the radio button
                        value: option.id, // Value for the radio button
                    }));
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
        getAllcategory() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/category")
                .then((res) => {
                    this.loader = false;
                    // this.Category_options = res["data"].map((option) => ({
                    //     text: option.name, // Display name for the radio button
                    //     value: option.id, // Value for the radio button
                    // }));
                    this.Category_options = res["data"].map((option) => option.name)
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },

        onSubmit(event) {
            this.$refs.editUserexpense.validate().then(success => {
                if (success) {
                    this.loader = true;
                    axios
                        .put(process.env.VUE_APP_API + '/expense/expense', {
                            'id': this.form.id,
                            'date': this.form.date,
                            'Supplier': this.selected_supplier,
                            'Category': this.selected_Category,
                            'Amount': this.form.Amount,
                            'Tax': this.form.Tax,
                        })
                        .then((res) => {
                            if (res.data.success) {
                                this.getAllexpenses();
                                this.loader = false;
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Expense Updated',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'success',
                                        },
                                    },
                                    {
                                        position: 'top-right',
                                    }
                                );
                            } else {
                                this.loader = false;
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Failed',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'danger',
                                        },
                                    },
                                    {
                                        position: 'top-left',
                                    }
                                );
                            }
                        })
                        .catch((error) => {
                            this.loader = false;
                            console.log(error);
                        });
                }
            });
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-edit-expense')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
